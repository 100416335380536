<template>
  <div>
    <b-modal
      id="add-user-modal"
      ref="add-user-modal"
      size="md"
      @hidden="cancel"
      :title="$t('modals.addUser.text')"
    >
      <!-- Name -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="name">{{$t("modals.name.text")}}</label>
          <b-form-input
            id="name"
            v-model="userAdd.name"
            class="mb-1"
            :placeholder="$t('modals.name.text')"
            :state="err.name ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.name }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- Email -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="email">{{$t("modals.email.text")}}</label>
          <b-form-input
            id="email"
            v-model="userAdd.email"
            class="mb-1"
            :placeholder="$t('modals.email.text')"
            :state="err.email ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.email }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- Role -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="role">{{$t("modals.role.text")}}</label>
          <b-form-select
            id="role"
            :options="roles"
            v-model="userAdd.role"
            class="mb-1"
            :state="err.role ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.role }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- Password -->
      <div class="row">
        <div class="col-6">
          <label for="password">{{$t("modals.password.text")}}</label>
          <b-form-input
            type="password"
            class="mb-1"
            id="password"
            v-model="userAdd.password"
            :placeholder="$t('modals.password.text')"
            :state="err.password ? false : null"
          >
          </b-form-input>
          <b-form-invalid-feedback>
            {{ err.password }}
          </b-form-invalid-feedback>
        </div>

        <div class="col-6">
          <label for="rePassword">{{$t("modals.rePassword.text")}}</label>
          <b-form-input
            type="password"
            class="mb-1"
            id="rePassword"
            v-model="rePassword"
            :placeholder="$t('modals.rePassword.text')"
            :state="err.rePassword ? false : null"
          >
          </b-form-input>
          <b-form-invalid-feedback>
            {{ err.rePassword }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="add(ok)">
              {{$t("buttons.add.text")}}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()"> {{$t("buttons.cancel.text")}} </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { authComputed } from "@/state/helpers";
export default {
  created() {},
  data() {
    return {
      userAdd: {
        name: null,
        email: null,
        role: null,
        password: null,
      },
      err: {
        name: null,
        email: null,
        role: null,
        password: null,
        rePassword: null,
      },
      rePassword: null,
    };
  },
  computed: {
    ...authComputed,
    roles() {
      if (this.loggedIn.user.role == "MANAGER") {
        return [
          { value: null, text: "-- Role --", disabled: true },
          { value: "MANAGER", text: "MANAGER" },
          { value: "OPERATOR", text: "OPERATOR" },
          { value: "USER", text: "USER" },
        ];
      } else if (this.loggedIn.user.role == "ADMIN") {
        return [
          { value: null, text: "-- Role --", disabled: true },
          { value: "OPERATOR", text: "OPERATOR" },
          { value: "USER", text: "USER" },
        ];
      } else {
        return [
          { value: null, text: "-- Role --", disabled: true },
          { value: "SYSADMIN", text: "SYSADMIN" },
          { value: "ADMIN", text: "ADMIN" },
          { value: "MANAGER", text: "MANAGER" },
          { value: "OPERATOR", text: "OPERATOR" },
          { value: "USER", text: "USER" },
        ];
      }
    },
  },
  watch: {},
  methods: {
    cancel() {
      this.clear();
    },
    clear() {
      this.userAdd = {
        name: null,
        email: null,
        role: null,
        password: null,
      };
      this.err = {
        name: null,
        email: null,
        role: null,
        password: null,
        rePassword: null,
      };
      this.rePassword = null;
    },
    add(ok) {
      let emailValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      let passwordValid = /^(?=.*?[A-Za-z])(?=.*?[0-9])[A-Za-z0-9].{7,128}$/;

      if (!this.userAdd.name) this.err.name = this.$t("msgError.obligField.text");
      else this.err.name = "";

      if (!this.userAdd.email) this.err.email = this.$t("msgError.obligField.text");
      else this.err.email = "";

      if (!this.userAdd.role) this.err.role = this.$t("msgError.obligField.text");
      else this.err.role = "";

      if (!this.userAdd.password)
        this.err.password = "Ce champ est obligatoire.";
      else this.err.password = "";

      if (!this.rePassword) this.err.rePassword = "Ce champ est obligatoire.";
      else this.err.rePassword = "";

      if (this.rePassword !== this.userAdd.password)
        this.err.rePassword = this.$t("msgError.rePasswordValidation.text");
      else this.err.rePassword = "";

      if (!emailValid.test(this.userAdd.email))
        this.err.email = this.$t("msgError.emailValidation.text");
      else this.err.email = "";

      if (!passwordValid.test(this.userAdd.password))
        this.err.password = this.$t("msgError.passwordValidation.text");
      else this.err.password = "";

      if (!this.err.name && !this.err.email && !this.err.role && !this.err.password && !this.err.rePassword) {
        ok();
        this.$emit("addUser", this.userAdd);
      }
    },
  },
};
</script>